import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Checkbox, Autocomplete, TextField } from "@mui/material";

interface DropDownProps {
    label: string,
    selectedValue: any,
    onSelection: (value: any) => void,
    items: any,
    keyProp?: string,
    valueProp?: string,
    displayProp?: string,
    multiple?: boolean,
    disabled?: boolean
}

const DropDownValue: React.FC<DropDownProps> = ({ label, selectedValue, onSelection, items, keyProp, valueProp, displayProp, multiple, disabled }) => {

    const handleChange = (event: any, newValue: any) => {
        if (valueProp) {
            if (Array.isArray(newValue)) {
                // If multiple values are selected, extract valueProp from each item
                newValue = newValue.map((item) => item[valueProp]);
            } else {
                // If a single value is selected, extract valueProp
                newValue = newValue[valueProp];
            }
        }
        onSelection(newValue);
    };

    return (
        <Box width="100%">
            <FormControl fullWidth margin="normal" disabled={disabled}>
                {/* <InputLabel sx={{ top: '-8px' }}>{label}</InputLabel> */}
                <Autocomplete
                    multiple={multiple}
                    options={items}
                    disableCloseOnSelect={multiple}
                    getOptionLabel={(option) => displayProp ? option[displayProp] : option}
                    value={selectedValue}
                    onChange={handleChange}
                    isOptionEqualToValue={(option, value) => (valueProp ? option[valueProp] : option) === (valueProp ? value[valueProp] : value)}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            {multiple && (
                                <Checkbox
                                    checked={selected}
                                    style={{ marginRight: 8 }}
                                />
                            )}
                            {displayProp ? option[displayProp] : option}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                        />
                    )}
                />
            </FormControl>
        </Box>
    );
}

export default DropDownValue;
