import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from '@mui/material';

interface FieldItemProps {
  fieldName: string;
  value: string | number | null; // You can specify more types as needed
  image_url?:boolean
}

const useStyles = makeStyles({
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 0',
  },
  heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#6e6e6e', // Medium gray
    width: '40%', // Adjust width for alignment
  },
  value: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#708090', // Slate gray
    textAlign: 'right', // Align values to the right
    width: '50%', // Adjust width for alignment
  },
  colon: {
    color: '#999', // Medium gray for colon
    textAlign: 'center', // Center the colon
    width: '10px', // Fixed width to ensure it centers correctly
    fontWeight: 'bold',
  },
  imageLink: {
    marginRight: 8,
    cursor: 'pointer',
  },
  imageIcon: {
    width: 50,  // or adjust to your needs
    height: 50,
    objectFit: 'cover',
  },
});

const FieldItem: React.FC<FieldItemProps> = ({ fieldName, value ,image_url}) => {
  const classes = useStyles();
  const renderImageIcons = (imageUrls:any) => {
    const urls = imageUrls.split(',');
    return (
      <div>
        {urls.map((url:any, index:any) => (
          <Link key={index} href={url} target="_blank" className={classes.imageLink}>
            <img src={url} alt={`image-${index}`} className={classes.imageIcon} />
          </Link>
        ))}
      </div>
    );
  };
  return (
    <div className={classes.detailRow}>
      <span className={classes.heading}>{fieldName}</span>
      <span className={classes.colon}>:</span>
      <span className={classes.value}>
        {image_url ? renderImageIcons(value) : value}
      </span>
    </div>
  );
};

export default FieldItem;
