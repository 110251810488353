import React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FilterSection from '../filterSection/filterSection';
interface Filter {
    key: string;
    value: string| string[];
    disabled: boolean;
    
  }

interface FilterDrawerProps {
    drawerWidth: number;
    drawerOpen: boolean;
    toggleDrawer: () => void;
    handleSearch: (filters : Filter[]) => void;
    filterKeys: string[];
    refresh:boolean;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ drawerWidth, drawerOpen, toggleDrawer, handleSearch, filterKeys, refresh }) => {
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
        >
            <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Typography variant="h6">
                    Filters
                </Typography>
                <IconButton onClick={toggleDrawer} aria-label="close drawer">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <FilterSection onSearch={handleSearch} dropdownKeys={filterKeys} refresh={refresh}/>
        </Drawer>
    );
};

export default FilterDrawer;