import { Box, Button, IconButton, Modal, TextField, Typography, Accordion, AccordionDetails, AccordionSummary, Snackbar,Alert } from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import Case from '../../interfaces/case';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import apiClient from '../../axiosClient';
import DropDown from '../baseComponents/dropdown';
import CaseDetail from '../../interfaces/caseDetail';

interface ModalProps {
    openSection: boolean,
    handleCloseSection: () => void,
    selectedRow: Case,
    caseDetailData: CaseDetail[]
}




const CallCustomerSection: FC<ModalProps> = ({ openSection, handleCloseSection, selectedRow, caseDetailData }) => {


    const [disableConnect, setDisableConnect] = useState(false);
    const [call_id, setCallId] = useState("");
    const [selectedNumber, setSelectedNumber] = useState<string>(selectedRow['customer_number']);
    const [selectableNumbers, setSelectableNumbers] = useState<string[]>([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');



    useEffect(() => {
        const additionalNumber: string[] = caseDetailData.filter((item) => (item.field_name === 'contact_number_2' && item.field_value !== null && item.field_value.trim() !== '')).map(item => item.field_value);
        setSelectableNumbers([selectedRow['customer_number'], ...additionalNumber]);
    }, [caseDetailData]);

    const handleCall = async () => {
        try {
            setSnackbarMessage('Call button clicked');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            const response = await apiClient.post('api/v1/initiate_call/' + selectedNumber);
            setDisableConnect(true);
            if (response.status === 200) {
                setSnackbarMessage('Call is successful');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                setTimeout(() => {
                    setDisableConnect(false);
                }, 5000);
                setCallId(response.data.call_id);
            } else {
                setSnackbarMessage('Call failed');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                setDisableConnect(false);
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
                alert(error)
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
            setDisableConnect(false);
        }
    };
    const handleCloseSnackbar = ()=>{
        setOpenSnackbar(false);
    }
    // const handleDisconnectCall = async () => {
    //     try {
    //         alert("clicked disconnect button: " + call_id);
    //         const response = await apiClient.post('api/v1/disconnect_call/' + call_id);
    //         if (response.status === 200) {
    //             alert("Disconnected the call")
    //             setDisableConnect(false)
    //             setCallId("")
    //         } else {
    //             alert("Failed to disconnect the call")
    //             setDisableConnect(true)
    //         }
    //     } catch (error: any) {
    //         if (error.response) {
    //             alert('Error response data:' + error.response.data.message);
    //             alert(error)
    //         } else if (error.request) {
    //             alert('No response received:' + error.request);
    //         } else {
    //             alert('Error message:' + error.message);
    //         }
    //         setDisableConnect(true);
    //     }
    // };


    return (
        <Accordion expanded={openSection} onChange={handleCloseSection}>
            <AccordionSummary expandIcon={<CallRoundedIcon color='success' />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography id="modal-title" variant="h6">
                    Call Customer
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box >
                    <Box mt={2} display="flex" justifyContent="center">
                        <Box width="80%">
                            <DropDown label="Choose Number" items={selectableNumbers} selectedValue={selectedNumber}
                                onSelection={(value) => setSelectedNumber(value)} />
                        </Box>
                    </Box>
                    <Box mt={2} display="flex" justifyContent={'space-evenly'}>
                        <IconButton color='success' size='large' onClick={handleCall} disabled={disableConnect}>
                            <CallRoundedIcon />
                        </IconButton>
                        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{vertical:'top', horizontal:'right'}}>
                            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%', fontSize:'20px' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );

};

const callStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
};

export default CallCustomerSection;