import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, TextField, Typography, IconButton, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Territory from '../../interfaces/territory';
import Picklist from '../../interfaces/picklist';
import DropDown from '../baseComponents/dropdown';
import DropDownValue from '../baseComponents/dropdownvalue';
import RoleManagement from '../../security/roleManagement';
import apiClient from '../../axiosClient';
import InternalUser from '../../interfaces/internal_user';

interface Filter {
  key: string;
  value: string| string[];
  disabled: boolean;
}

interface FilterSectionProps {
  dropdownKeys: string[];
  onSearch: (filters: Filter[]) => void;
  refresh: boolean;
}

const FilterSection: React.FC<FilterSectionProps> = ({ dropdownKeys, onSearch, refresh }) => {
  const [filters, setFilters] = useState<Filter[]>([{ key: '', value: [], disabled: false }]);

  let dropdownBasedKeys = ['client_id', 'territory_id', 'status', 'loan_type', 'dpd_bucket','assigned_at','assigned_to'];

  const clients = useSelector((state: RootState) => state.clients.clients);
  const territories: Territory[] = useSelector((state: RootState) => state.territories.territories);
  const picklist: Picklist = useSelector((state: RootState) => state.picklists.picklists);

  const [userpicklist, setUserpicklist] = useState<string[]|undefined>([]);
  
  useEffect(() => {
    const initialize = async () => {
      let fetchedUserList= await fetchUsers("");
      setUserpicklist (fetchedUserList);
      handleSearch();
    };
    initialize();
  }, []);   
  
  useEffect(()=>{handleSearch()},[refresh]);

  const handleAddFilter = () => {
    setFilters([...filters, { key: '', value: [], disabled: false }]);
  };
  
  const handleRemoveFilter = (index: number) => {
    filters.splice(index, 1);
    setFilters([...filters]);
  }
  
  const handleFilterChange = (index: number, key: string, value: string |string[], disabled = false) => {
    
      const updatedFilters = [...filters];
      const valueArray = Array.isArray(value) ? value : [value];
      updatedFilters[index] = { key, value: valueArray, disabled };
      setFilters(updatedFilters);
    
  };
  
  const handleReset = () => {
    setFilters([{ key: '', value: [], disabled: false }]);    
  };
  
  const handleSearch = () => {
    onSearch(filters);
  };
  
  const fetchUsers = async (searchQuery: string) => {
    try {
      const response = await apiClient.get(`/api/v1/internal_user?username=` + searchQuery);
      
      const results: string[] = [];
      
      response.data?.forEach((element: InternalUser) => {
        results.push(element.username);
      });
      return results;
    } catch (error: any) {
      if (error.response) {
        alert('Error response data:' + error.response.data.message);
      } else if (error.request) {
        alert('No response received:' + error.request);
      } else {
        alert('Error message:' + error.message);
      }
    }
  }
  
  
  return (
    <Box mb={3} border={1} borderColor="grey.300" p={2} borderRadius={2}>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
        <Button variant="contained" onClick={handleSearch}>
          Apply
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          Reset
        </Button>
      </Box>
      {filters.map((filter, index) => (
        <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, marginBottom: 2 }}>
          <Box width={'100%'}>
            <DropDown label="Key" items={dropdownKeys} selectedValue={filter.key} disabled={filter.disabled}
              onSelection={(value) => handleFilterChange(index, value, filter.value)} />
          </Box>
          <Box width={'100%'} sx={{
            flex: '5 1 0%',
            pointerEvents: filter.disabled ? 'none' : 'auto',
            opacity: filter.disabled ? 0.6 : 1
          }}>
            {!dropdownBasedKeys.includes(filter.key) &&
              <TextField
                margin='normal'
                label="Value"
                value={filter.value}
                onChange={(e) => handleFilterChange(index, filter.key, e.target.value)}
                fullWidth
              />}
            {filter.key === 'client_id' && <DropDown label="Select Client" items={clients} selectedValue={filter.value} multiple={true}
              onSelection={(value) => handleFilterChange(index, filter.key, value)} keyProp="client_id" valueProp="client_id" displayProp="client_name" />}
            {filter.key === 'territory_id' && <DropDown label="Select Territory" items={territories} selectedValue={filter.value} multiple={true}
              onSelection={(value) => handleFilterChange(index, filter.key, value)} keyProp="territory_id" valueProp="territory_id" displayProp="name" />}
            {filter.key === 'status' && <DropDownValue label="Select Status" items={picklist.STATUS} selectedValue={filter.value} multiple={true}
              onSelection={(value) => handleFilterChange(index, filter.key, value)} />}
            {filter.key === 'loan_type' && <DropDownValue label="Select Loan Type" items={picklist.LOAN_TYPE} selectedValue={filter.value} multiple ={true}
              onSelection={(value) => handleFilterChange(index, filter.key, value)} />}
            {filter.key === 'dpd_bucket' && <DropDownValue label="Select DPD Bucket" items={picklist.DPD_BUCKET} selectedValue={filter.value} multiple={true}
              onSelection={(value) => handleFilterChange(index, filter.key, value) }  />}
            {filter.key === 'assigned_at' && <DropDownValue label="Select assigned_at" items={picklist.USER_ROLE} selectedValue={filter.value} multiple={true}
              onSelection={(value) => handleFilterChange(index, filter.key, value) }  />}
            {filter.key === 'assigned_to' && <DropDownValue label="Select assigned_to" items={userpicklist} selectedValue={filter.value} multiple={true}
              onSelection={(value) => handleFilterChange(index, filter.key, value) }  />}
          </Box>
          <Box sx={{ flex: '2 1 0%' }}>
            {index === filters.length - 1 ? (
              <IconButton onClick={handleAddFilter} sx={{ marginBottom: 2 }}>
                <AddIcon />
              </IconButton>
            ) : (
              <IconButton disabled={filter.disabled} onClick={() => handleRemoveFilter(index)} sx={{ marginBottom: 2 }}>
                <RemoveIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      ))}



    </Box>
  );
};

export default FilterSection;
